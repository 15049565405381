

















import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class Teammichael extends Mixins(View) {
  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrCoreLogicIngegration') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://dpr.leadplus.com.au/main.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scrCoreLogicIngegration';

      scr.onload = () => {
        console.log('Core Logic Integration Loaded');
      };

      document.body.append(scr);
    }, 500);

    const intervalId = setInterval(() => {
      console.log('interval running');
      // step 1
      const daIntegration = document.getElementById('daintegration')!;
      if (daIntegration) {
        clearInterval(intervalId);
        console.log('Da integration found');
        const shadowRoot = daIntegration.shadowRoot!;

        const nextButton = shadowRoot.getElementById('da_optional_address_suggest')!;

        nextButton.addEventListener('click', () => {
          console.log('/////////////////////////////////');
          const form = shadowRoot.querySelectorAll('form')[0];
          console.log(form);

          let reportType;
          const propertyRadioBtn = form.querySelectorAll('#da_optional_property')[0] as HTMLInputElement;
          const rentalRadioBtn = form.querySelectorAll('#da_optional_rental')[0] as HTMLInputElement;
          if (propertyRadioBtn.checked) {
            reportType = 'Sales report';
          } else if (rentalRadioBtn.checked) {
            reportType = 'Rental report';
          }

          const homeAddress = form.querySelectorAll('#da_optional_address_text')[0] as HTMLInputElement;

          this.$root.$data.coreLogicFormData.reportType = reportType;
          this.$root.$data.coreLogicFormData.homeAddress = homeAddress.value;
          console.log('/////////////////////////////////');
          console.log(this.$root.$data.coreLogicFormData.homeAddress);
        });
      }
    }, 500);
  }
}
